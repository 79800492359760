import React from "react";
export default function About() {
    return (
      <>
        <div className="pl-5 pt-5 text-white">
                <h4 className='font-weight-bold'>Is this a rug pull?</h4>
          <p>Head over to etherscan and check out our contract. You&apos;ll find it&apos;s safe!</p>
          <p>We provide free to low cost NFTs to help you understand the concepts of a DAPP, GAS and general crypto concepts</p>
        </div>
        <div className="pl-5 text-white">        
          <h4 className="text-warning">DAPP/GAS</h4>        
          <p>A <strong className="text-warning">DAPP</strong> can be a page within a website or a standalone single page website.</p> 
          <p>The purpose of the <strong className="text-warning">DAPP</strong> is to interact with the NFT contract, think of it as the checkout cart.</p>
          <p><strong className="text-warning">GAS </strong> is the <span className="text-warning">fee paid </span> to use the Ethereum network. The<strong className="text-warning"> GAS </strong> is 'demand pricing' depending on the network traffic.</p>

          <h4 className="text-warning">Coin Prices</h4>
          <p>The current price of 3 popular coins in 1 location.</p>
          <p>Coin prices start at an initial default state of 1 and are updated every minute using industry standard API protocols.</p>
          <p>If you see a coin price of 1, it's an indicatation of a failed API</p>
          <p>The DAPP relies on the API to return the current ETH price to convert the payable to USD. The estimated USD price is for your convenience. The contract deals only with ETH, you can always mint directly from the contract. </p>
          <p>Failed APIs are out of our control. Occasionally the API fails but eventually prices are returned though the timeframe varies</p>
        </div>
    </>
  );
}